import React from 'react'

export const LearnMore = () => (
  <div
    style={{
      display: `flex`,
      marginBottom: '30px',
      fontSize: '14px',
      backgroundColor: '#eeeeee',
      borderRadius: '5px',
      padding: '10px'
    }}
  >
    <p>
      Vous voulez en savoir plus sur le JavaScript? <strong>Gagnez en confiance</strong> avec notre série de vidéos sur 3 jours pour démystifier les notions clés du JS !<br/>
      <a href="https://private.devtheory.fr/demystifie-le-javascript?utm_source=challenge" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>
      S'inscrire au cours gratuit dès maintenant
      </a> pour apprendre rapidemment tout en créant des compétences, et en réduisant le syndrome de l'imposteur.
    </p>
  </div>
)
