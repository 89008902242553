import React from 'react';
import './bio.css';
import { LearnMore } from './learn-more';

const Bio = () => {
  return (
    <>
      <div
        style={{
          display: `flex`,
          marginBottom: '30px',
          fontSize: '14px'
        }}
      >
        <p>
          Testez vos connaissances sur le JavaScript avec ce challenge
        </p>
      </div>
      <LearnMore />
    </>
  );
};

export default Bio;
